import { Box, Grid } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCountryList } from '../../store/entities/selectors';
import Modal from '../Modal';

interface Props {
  list: string[] | null;
  onClose: () => void;
}

export default function CountriesModal({ list, onClose }: Props) {
  const allCountries = useSelector(selectCountryList(false, false));
  const planCountries = list?.map((code: string) => allCountries[code]);

  if (!list) {
    return null;
  }

  return (
    <Grid item xs={12} md={6}>
      <Modal
        forceOpen={true}
        button={null}
        onCloseCb={onClose}
        renderContent={() => (
          <div>
            <h2> Countries </h2>
            <Box display="flex" flexWrap="wrap">
              {planCountries?.map((country: any, idx: number, arr: any) => (
                <Box m={0.5} key={country.code} display="flex" flexWrap="wrap">
                  {`${country.name}(${country.code})${arr.length - 1 === idx ? '' : ', '}`}
                </Box>
              ))}
            </Box>
          </div>
        )}
      />
    </Grid>
  );
}

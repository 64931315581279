import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { useStandardFormStyles } from '@root/components/FormBase';
import InputSelect from '@root/components/InputSelect';
import { createInitialValue } from '@root/pages/DeclineOrders/helpers';
import { editFields } from '@root/pages/DeclineOrders/static-data';
import { useStyles } from '@root/pages/DeclineOrders/styles';
import { IBodyData } from '@root/pages/DeclineOrders/types';
import { IGateway } from '@root/services/api/requests/getGatewayList';
import { ITransactions, uploadFailedOrderList } from '@root/services/api/requests/orders';
import setNotification from '@root/utils/notifications';
import { uniqBy } from 'lodash';
import { FC, useMemo, useState } from 'react';

interface IProps {
  failedTransaction: ITransactions;
  gateways: IGateway[];
}

export const EditableCell: FC<IProps> = ({ failedTransaction, gateways }) => {
  const classes = { ...useStandardFormStyles(), ...useStyles() };
  const [itemForEdit, setItemForEdit] = useState<IBodyData>(() =>
    createInitialValue(failedTransaction)
  );

  const gatewaysOptions = useMemo(
    () =>
      gateways
        .filter((item) => item.gatewayMethod.name === itemForEdit.paymentMethod)
        ?.map((item) => ({ value: item.rebillyId, label: item.rebillyId })),
    [gateways, itemForEdit.paymentMethod]
  );

  const paymentMethods = useMemo(() => {
    const methods = gateways?.map((item) => ({
      value: item.gatewayMethod.name,
      label: item.gatewayMethod.name,
    }));

    return uniqBy(methods, 'value');
  }, [gateways, itemForEdit.gatewayAccountId]);

  const updateDeclineOrderList = async (itemForEdit: IBodyData): Promise<boolean> => {
    try {
      const res = await uploadFailedOrderList(itemForEdit);

      if (res?.status === 200) {
        setNotification('success', {
          message: 'Success',
        });
      }
      return true;
    } catch (err) {
      console.error(err.message);
      return false;
    }
  };

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setItemForEdit((prev) => ({ ...prev, [name]: value }));
  };

  const onSave = async () => {
    const isSuccess: boolean = await updateDeclineOrderList(itemForEdit);
    if (isSuccess) {
      setItemForEdit(() => createInitialValue(failedTransaction));
    }
  };

  return (
    <>
      <TableRow>
        <TableCell variant="head">{editFields[0].label}</TableCell>
        <TableCell align="right">
          <InputSelect
            type="string"
            id="paymentMethod"
            value={itemForEdit.paymentMethod}
            name="paymentMethod"
            onChange={(e) => setValue(e as React.ChangeEvent<HTMLInputElement>)}
            options={paymentMethods.map((el) => ({ value: el.label, label: el.label }))}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell variant="head">{editFields[1].label}</TableCell>
        <TableCell align="right">
          <InputSelect
            type="string"
            id="gatewayAccountId"
            value={itemForEdit.gatewayAccountId}
            name="gatewayAccountId"
            onChange={(e) => setValue(e as React.ChangeEvent<HTMLInputElement>)}
            options={gatewaysOptions}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell variant="head">{editFields[2].label}</TableCell>
        <TableCell align="right">
          <TextField
            required
            className={classes.inputWidth}
            id="adminExternalPaymentId"
            name="adminExternalPaymentId"
            value={itemForEdit.adminExternalPaymentId}
            onChange={setValue}
          />
        </TableCell>
      </TableRow>
      <Button
        color="primary"
        variant="contained"
        className={classes.submit}
        type="button"
        onClick={onSave}
      >
        Push Order
      </Button>
    </>
  );
};

import { Button, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputSelect from '@root/components/InputSelect';
import { useToolbarStyles } from "@root/components/MembershipRulesTable/styles";
import { IFilters } from '@root/components/MembershipRulesTable/types';
import { IOption } from '@root/utils/commonTypes';
import { ChangeEvent, FC } from 'react';

interface IMembershipRuleFilters {
  filters: IFilters;
  levelsOptions: IOption[];
  handleFilterChange: (
    e: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>
  ) => void;
  handleFilterApply: () => void;
}

export const MembershipRulesFilters: FC<IMembershipRuleFilters> = ({
  filters,
  levelsOptions,
  handleFilterChange,
  handleFilterApply,
}) => {
  const classes = useToolbarStyles();

  return (
    <Grid container spacing={2} alignItems={'center'} className={classes.filterRow}>
      <Grid item xs={12} sm={6}>
        <InputSelect
          id="From"
          label="From"
          name="from"
          variant="outlined"
          fullWidth
          options={levelsOptions}
          value={filters.from}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputSelect
          id="To"
          label="To"
          name="to"
          variant="outlined"
          fullWidth
          options={levelsOptions}
          value={filters.to}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="Recurring Type"
          label="Recurring Type"
          name="recurringType"
          variant="outlined"
          fullWidth
          value={filters.recurringType}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button className={classes.filterButton} variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

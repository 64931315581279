import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { FilterToggle } from '@root/components/FilterToggle';
import { MembershipRulesFilters } from '@root/components/MembershipRulesTable/MembershipRulesFilters';
import { headCells } from '@root/components/MembershipRulesTable/static-data';
import { IProps } from '@root/components/MembershipRulesTable/types';
import { useMembershipRulesTable } from '@root/components/MembershipRulesTable/use-membership-rules-table';
import Can, { Permissions } from '../Can';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import TableSkeleton from '../TableSkeleton';

const MembershipRulesTable = (props: IProps) => {
  const { loading, rules, handleEdit, handleDelete, levels } = props;
  const classes = useStandardTableStyles();
  const [
    { filters, isFilterPanelOpen, skuHeaders, filteredListOfRules },
    { handleFilterChange, getLevelNameById, levelsOptions, toggleFilterPanel, handleFilterApply },
  ] = useMembershipRulesTable(levels, rules);

  return (
    <>
      <FilterToggle onFilterClick={toggleFilterPanel} />
      {isFilterPanelOpen && (
        <MembershipRulesFilters
          filters={filters}
          levelsOptions={levelsOptions()}
          handleFilterChange={handleFilterChange}
          handleFilterApply={handleFilterApply}
        />
      )}
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={[...skuHeaders, ...headCells]} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !rules?.length ? (
              <TableRow>
                <TableCell>There are no membership rules</TableCell>
              </TableRow>
            ) : (
              !loading &&
              filteredListOfRules?.length > 0 &&
              filteredListOfRules?.map((item) => (
                <TableRow key={item.id}>
                  {skuHeaders?.map(({ id }) => (
                    <TableCell key={id}>{item.skus?.[id] || '-'}</TableCell>
                  ))}
                  <TableCell>{getLevelNameById(item.membershipLevelFromId)}</TableCell>
                  <TableCell>{getLevelNameById(item.membershipLevelToId)}</TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.updateUpgradeMembershipRule}>
                      <IconButton aria-label="edit" title={'Edit'} onClick={() => handleEdit(item)}>
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.deleteUpgradeMembershipRule}>
                      <DeleteModalButton
                        name={`Rule ${item.skus?.Monthly || '-'} / ${item.skus?.Annual || '-'}`}
                        entity={'membership rules'}
                        onDelete={() => handleDelete(item.id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MembershipRulesTable;

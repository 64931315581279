import { Box, Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getNameWithRecurringType } from '@root/components/CreateEditProduct/helpers';
import 'date-fns';
import * as React from 'react';
import CountriesModal from './countries-modal';
import PlanGroupItem from './plan-group-item';
import { usePlanController } from './use-plan-controller';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

const PlanGroups: React.FC<{}> = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const [
    { planCountries, groups },
    { createPlan, handleDeletePlan, handleEditPlan, handlePlanStatusChange, showPlanCountries },
  ] = usePlanController();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const noPlansCreated = !groups[0].length && !groups[1].length;

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        {groups.map((item) =>
          item?.map((group) => (
            <Accordion
              key={group[0]?.planGroupId}
              expanded={expanded === `panel${group[0]?.planGroupId}`}
              onChange={handleChange(`panel${group[0]?.planGroupId}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {getNameWithRecurringType(group[0]?.name, group[0]?.recurringType)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box gridGap={16} width="100%" display="flex" flexDirection="column">
                  {group.map((plan) => (
                    <PlanGroupItem
                      key={plan.id}
                      plan={plan}
                      handleDeletePlan={handleDeletePlan}
                      handleEditPlan={handleEditPlan}
                      handlePlanStatusChange={handlePlanStatusChange}
                      showPlanCountries={showPlanCountries}
                    />
                  ))}
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => createPlan(group[0]?.planGroupId)}
                  >
                    + Create Regional Bundle
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        )}

        <Box mt="16px" maxWidth={noPlansCreated ? 600 : undefined}>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => createPlan()}
          >
            + Create Bundle
          </Button>
        </Box>
      </div>

      <CountriesModal list={planCountries} onClose={() => showPlanCountries(null)} />
    </Grid>
  );
};

export default PlanGroups;

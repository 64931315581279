import { selectPlansAsObjects } from '@root/store/entities/selectors';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import installmentRequests, {
  IInstallmentOrder,
} from '../../services/api/requests/installmentsOrders';
import { IMeta } from '../../services/api/types';
import setNotification from '../../utils/notifications';

export const useInstallmentsOrdersTable = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [list, setList] = useState<IInstallmentOrder[]>([]);
  const planData = useSelector(selectPlansAsObjects);

  const fetchList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await installmentRequests.getList({
        page,
        limit: rowsPerPage,
        planData,
      });

      setLoading(false);
      setList(response.items);

      setMeta(response.meta);
    } catch (e) {
      console.warn((e as AxiosError).message);
    }
  }, [setList, setMeta, setLoading, page, rowsPerPage]);

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleStatusChange = async (statuses: Record<string, string>) => {
    Promise.all(
      Object.entries(statuses)
        .filter(([, status]) => ['approve', 'deny'].includes(status))
        .map(async ([id, status]) => {
          await installmentRequests.changeOrderStatus(Number(id), status as 'approve' | 'deny');
          setNotification('success', {
            message: 'Success',
          });
        })
    )
      .then(() => fetchList())
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchList().then(console.log);
  }, [fetchList, setLoading]);

  const state = {
    list,
    meta,
    page,
    rowsPerPage,
    loading,
  };

  const handlers = {
    handleStatusChange,
    handleChangePage,
    handleChangeRowsPerPage,
    handleListFetch: fetchList,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};

import { Box, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import FlagIcon from '@material-ui/icons/Flag';
import clsx from 'clsx';
import 'date-fns';
import * as React from 'react';
import { IParams } from '../../services/api/requests/changePlanStatus';
import { IPlan } from '../../services/api/requests/getProductPlans';
import Can, { Permissions } from '../Can';
import DeleteModalButton from '../DeleteModalButton';
import EditStatusButton from '../EditStatusButton';
import { useStandardFormStyles } from '../FormBase';
interface IPlanGroupItemProps {
  plan: IPlan;
  handlePlanStatusChange(data: IParams): void;
  handleEditPlan(plan: IPlan): void;
  handleDeletePlan(id: number): void;
  showPlanCountries(list: IPlan['countryCodes']): void;
}

const PlanGroupItem: React.FC<IPlanGroupItemProps> = (props) => {
  const classes = useStandardFormStyles();
  const { plan, handleDeletePlan, handleEditPlan, handlePlanStatusChange, showPlanCountries } =
    props;
  const price = `$${plan.setupPrice}/$${plan.recurringPrice || '-'}`;

  return (
    <Button
      className={clsx(classes.boxButton, classes.noMargin)}
      fullWidth
      color="primary"
      variant={'outlined'}
    >
      <Box display={'flex'} justifyContent="space-between" width={'100%'} alignItems={'center'}>
        <div onClick={() => showPlanCountries(plan.countryCodes)}>
          <span>{plan.name}</span>
          <IconButton aria-label="countries" title={'Countries'} size={'small'}>
            <FlagIcon />
          </IconButton>
        </div>
        <div>{price}</div>
        <div>
          <EditStatusButton
            status={plan.status}
            onChange={(status: string) => handlePlanStatusChange({ id: plan.id, status })}
          />

          <Can perform={Permissions.updateCoupon}>
            <IconButton
              aria-label="edit"
              title={'Edit'}
              size={'small'}
              onClick={() => handleEditPlan(plan)}
            >
              <EditIcon />
            </IconButton>
          </Can>

          <Can perform={Permissions.deleteCoupon}>
            <DeleteModalButton
              name={plan.name}
              entity={'Bundle'}
              onDelete={() => handleDeletePlan(plan.id)}
            />
          </Can>
        </div>
      </Box>
    </Button>
  );
};

export default PlanGroupItem;

import { IFilters } from '@root/components/MembershipRulesTable/types';
import { IMembershipLevel } from '@root/services/api/requests/membershipLevels';
import { IMembershipRule } from '@root/services/api/requests/membershipRules';
import { IOption } from '@root/utils/commonTypes';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

export const useMembershipRulesTable = (
  levels: IMembershipLevel[] | null,
  rules: IMembershipRule[] | null
) => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const [filteredListOfRules, setFilteredListOfRules] = useState<IMembershipRule[]>([]);
  const [filters, setFilters] = useState<IFilters>({
    from: 'All',
    to: 'All',
    recurringType: '',
  });

  const getLevelNameById = useCallback(
    (id: string | number) => {
      if (levels) {
        return levels.find((l) => l.id === +id)?.name;
      }
      return id;
    },
    [levels]
  );

  const skuHeaders = useMemo(() => {
    const sortedRules = rules?.sort(
      (a, b) => Object.keys(b.skus).length - Object.keys(a.skus).length
    );
    const maxSkus = Object.keys(sortedRules?.[0].skus || {});
    const skus = maxSkus.length ? maxSkus : ['Annual', 'Monthly'];
    return skus.sort().map((item) => ({
      id: item,
      numeric: false,
      disablePadding: false,
      label: item + ' SKU',
      disableSort: true,
    }));
  }, [rules]);

  const toggleFilterPanel = useCallback(() => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  }, [isFilterPanelOpen]);

  const levelsOptions = useCallback(() => {
    if (levels === null) return [];

    return levels.reduce<IOption[]>(
      (options, value, i) => {
        options.push({ value: value.name, label: value.name });
        return options;
      },
      [{ label: 'All', value: 'All' }]
    ) as IOption[];
  }, []);

  const handleFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => {
      const { name, value } = e.target;
      setFilters((prev: IFilters) => ({ ...prev, [name as string]: value }));
    },
    []
  );

	const handleFilterApply = useCallback(() => {
    if (rules === null) return;

    let filteredRules = rules;

    if (filters.from !== 'All') {
      filteredRules = filteredRules.filter(
        (rule) => getLevelNameById(rule.membershipLevelFromId) === filters.from
      );
    }
    if (filters.to !== 'All') {
      filteredRules = filteredRules.filter(
        (rule) => getLevelNameById(rule.membershipLevelToId) === filters.to
      );
    }
    if (filters.recurringType !== '') {
      filteredRules = filteredRules.filter((rule) =>
        Object.values(rule.skus).includes(filters.recurringType)
      );
    }

    setFilteredListOfRules(filteredRules);
  }, [rules, filters, getLevelNameById]);

  useEffect(() => {
    if (rules === null) return;

    setFilteredListOfRules(rules);
  }, [rules]);

  const state = {
    filters,
    filteredListOfRules,
    isFilterPanelOpen,
    skuHeaders,
  };

  const handlers = {
    handleFilterChange,
    getLevelNameById,
    levelsOptions,
    toggleFilterPanel,
    handleFilterApply,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};

import { makeStyles } from "@material-ui/core";

export const useToolbarStyles = makeStyles(() => ({
  filterRow: {
    padding: '0 16px',
		marginBottom: 16,
  },

	filterButton: {
		height: 56,
		fontSize: 18,
	},
}));

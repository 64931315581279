import { IFormValues } from './types';

export const initialValues: IFormValues = {
  name: '',
  rebillyProductId: '',
  description: '',
  file: '',
  bgColor: '#fff',
  color: '#000',
  priority: 0,
  status: 'active',
  imageUrl: null,
  fullImageUrl: null,
  urlParam: '',
};

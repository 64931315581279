import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import deleteWhitelistIp from '../../services/api/requests/deleteWhitelistIp';
import getWhiteListIps, { IIp } from '../../services/api/requests/getWhiteList';
import { IMeta } from '../../services/api/types';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  {
    id: 'ipAddress',
    numeric: false,
    disablePadding: false,
    label: 'IP Address',
    disableSort: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
    disableSort: true,
  },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];

const WhiteList: FC<any> = () => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const dispatch = useDispatch();
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [whiteList, setList] = React.useState<IIp[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const fetchWhiteList = async () => {
    try {
      const response = await getWhiteListIps({
        page,
        limit: rowsPerPage,
      });

      setLoading(false);
      setList(response.data.items);
      setMeta(response.data.meta);
    } catch (e) {
      console.warn(e.message);
    }
  };
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchWhiteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleDelete = async (id: number) => {
    try {
      const response = await deleteWhitelistIp(id);
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        await fetchWhiteList();
      }
    } catch (e) {
      console.warn(e.message);
    }
  };
  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Whitelist</Title>
            <Can perform={Permissions.expandIpWhitelist}>
              <Button
                onClick={() => dispatch(push(pageLinks.createWhiteListIp))}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add IP address
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !whiteList?.length ? (
              <TableRow>
                <TableCell>There are no IPs</TableCell>
              </TableRow>
            ) : (
              whiteList.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell className={classes.whiteListCell}>{item.ipAddress}</TableCell>
                    <TableCell className={classes.whiteListCell}>{item.description}</TableCell>
                    <TableCell className={classes.whiteListCell}>
                      {dateFns.format(new Date(item.createdAt), 'dd/MM/yyyy hh:mm')}
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.deleteIpFromWhitelist}>
                        <DeleteModalButton
                          name={item.ipAddress}
                          entity={'IP from whitelist'}
                          onDelete={() => handleDelete(item.id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default WhiteList;

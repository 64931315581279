import { ProductContext } from '@root/components/CreateEditProduct/product-context';
import changePlanStatus, { IParams } from '@root/services/api/requests/changePlanStatus';
import deletePlan from '@root/services/api/requests/deletePlan';
import getPlanById from '@root/services/api/requests/getPlanById';
import { getPlanMissedCountries } from '@root/services/api/requests/getPlanMissedCountries';
import { IProductListItem } from '@root/services/api/requests/getProductList';
import { IPlan, IProductWithPlans } from '@root/services/api/requests/getProductPlans';
import { editPlan } from '@root/store/entities/actions';
import { selectProductForEditing } from '@root/store/entities/selectors';
import { setSuccessNotification } from '@root/utils/notifications';
import pageLinks from '@root/utils/pageLinks';
import { push } from 'connected-react-router';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const usePlanController = () => {
  const productForEditing = useSelector(selectProductForEditing);
  const dispatch = useDispatch();
  const { product, fetchProduct } = useContext(ProductContext);
  const [planCountries, setPlanCountries] = useState<string[] | null>(null);
  const isEdit = product?.id;

  const groups = useMemo(() => {
    return sortBy(
      Object.values(groupBy(product?.plans, 'planGroupId')).map((group) => {
        let main;
        let active: IPlan[] = [];
        let inactive: IPlan[] = [];

        for (let i = 0; i < group.length; i++) {
          const isRegionalPlan = group[i].isRegional;
          if (!isRegionalPlan) {
            main = group[i];
          } else if (isRegionalPlan && group[i].status === 'active') {
            active.push(group[i]);
          } else {
            inactive.push(group[i]);
          }
        }
        return [main as IPlan, ...active, ...inactive];
      }),
      [(plan: IPlan[]) => plan[0]?.name]
    ).reduce(
      (acc, val: IPlan[]) => {
        if (val[0].intervalSize === 365) {
          acc[1] = [...acc[1], val];
        } else {
          acc[0] = [...acc[0], val];
        }

        return acc;
      },
      [[], []] as Array<IPlan[][]>
    );
  }, [product]);

  const isAddon = (product: IProductWithPlans | null) => {
    return product && product.urlParam === 't-addons';
  };

  const getAvailableCountries = useCallback(async (planGroupId: IPlan['planGroupId']) => {
    try {
      const response = await getPlanMissedCountries(planGroupId);
      return response.data.data.map((item) => item.code);
    } catch (error) {
      console.error('Error: ', error);
      return undefined;
    }
  }, []);

  const createPlan = async (planGroupId: number | void) => {
    try {
      if (planGroupId) {
        const plan = product?.plans.find(
          (plan) => plan.planGroupId === planGroupId && !plan.isRegional
        );

        const fullPlanData = await getPlanById({
          productId: isEdit ? Number(product?.id) : 0,
          planId: plan?.id as number,
        });

        const availableCountryCodes = await getAvailableCountries(planGroupId);

        const planForEditing = {
          ...fullPlanData.data.data,
          availableCountryCodes: availableCountryCodes?.length ? availableCountryCodes : undefined,
        };

        dispatch(editPlan(planForEditing));
      }

      dispatch(
        push(
          pageLinks.createEditPlan +
            `?productId=${productForEditing?.id || product?.id}${
              isAddon(product) ? '&isAddon=true' : ''
            }${planGroupId ? '&action=create&planGroupId=' + planGroupId : ''}${
              planGroupId ? '&isRegionalPlan=true' : ''
            }`
        )
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleEditPlan = useCallback(
    async (plan: IPlan) => {
      try {
        const fullPlanData = await getPlanById({
          productId: isEdit ? Number(product?.id) : 0,
          planId: plan.id,
        });

        const availableCountryCodes = await getAvailableCountries(plan.planGroupId);

        const planForEditing = {
          ...fullPlanData.data.data,
          availableCountryCodes: availableCountryCodes?.length ? availableCountryCodes : undefined,
        };

        dispatch(editPlan(planForEditing));
        dispatch(
          push(
            pageLinks.createEditPlan +
              `?productId=${product?.id}&isRegionalPlan=${plan.isRegional}&planId=${plan.id}${
                isAddon(product) ? '&isAddon=true' : ''
              }${plan.planGroupId ? `&planGroupId=${plan.planGroupId}` : ''}`
          )
        );
      } catch (e) {
        console.warn('error', e);
      }
    },
    [dispatch, isEdit, product, getAvailableCountries]
  );

  const handleDeletePlan = useCallback(
    async (id: number) => {
      const response = await deletePlan(id);
      if (response) {
        setSuccessNotification('Plan has been deleted');
        fetchProduct().then(console.log);
      }
    },
    [fetchProduct]
  );

  const handlePlanStatusChange = useCallback(
    async (data: IParams) => {
      const response = await changePlanStatus(data);
      if (response) {
        setSuccessNotification();
        fetchProduct().then(console.log);
      }
    },
    [fetchProduct]
  );

  const showPlanCountries = useCallback(
    (countries: string[] | null) => {
      setPlanCountries(countries);
    },
    [setPlanCountries]
  );

  const state = { product, isEdit, planCountries, groups };

  const handlers = {
    createPlan,
    handleEditPlan,
    handleDeletePlan,
    handlePlanStatusChange,
    showPlanCountries,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
